@import "variables";
@import "mixins";

.report-toolbar {
  @include for-tablet-portrait-up {
    height: 70px;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .form-control {
    margin-bottom: 0;
    height: 52px;
  }
}

.btn-toolbar {
  height: 50px;
  column-gap: 10px;
  @include for-phone-only {
    height: 100px;
    display: block;
  }
}

.report-table {
  th:nth-child(1) {
    width: 38px;
  }
  th:nth-child(2) {
    width: 95px;
  }
  td:nth-child(2) {
    padding: 0;
  }
  .thumbnail {
    padding: 0;
  }
  .thumbnail.card-svg {
    padding: 0.75rem;
  }
  .selected {
    background-color: $blue !important;
    color: $white !important;
    td {
      color: $white !important;
    }
  }
}