@import url("https://fonts.googleapis.com/css?family=Fira+Sans&display=swap");
$fira: "Fira Sans", sans-serif;
$mont: "Montserrat", sans-serif;

$light-blue: #007bc7 !default;
$blue: #003f87 !default;
$black: #000000 !default;
$white: #ffffff !default;
$gray: #767676 !default;
$bg-gray: #e6ebef !default;
$red: #cc2d30 !default;
$yellow: #ffc61e !default;
$light-green: #bad80a !default;
$green: #006b3f !default;
$as-blue: #0083d6 !default;
$as-yellow: #fff683 !default;

$font-size-xs: 14px !default;
$font-size-s: 20px !default;
$font-size-m: 28px !default;
$font-size-l: 32px !default;
$font-size-xl: 40px !default;
