@import "variables";
@import "mixins";

html {
  width: 100%;
}

body {
  width: 100%;
  background-color: #ededf5;
  font-family: $fira;
}

video {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $mont;
  font-weight: bolder;
  color: $blue;
}

h1 {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.splash {
  display: none;
  @include for-tablet-landscape-up {
    display: grid;
  }
}

.spinner-grow {
  margin: 100px auto;
  display: block;
}

.western-avatar {
  position: absolute;
  right: 10px;
  top: 55px;
  z-index: 10;
}

.avatar-department,
.avatar-level {
  margin-top: 0px;
  color: $white;
  text-align: center;
  font-size: 16px;
  @include for-tablet-landscape-up {
    color: $blue;
    font-size: 18px;
    margin-top: 8px;
  }
}

.avatar-level {
  margin-top: -34px;
  color: $white;
  font-size: 16px;
}

.sb-avatar__text {
  background: $blue !important;
}

.description {
  padding: 10px 20px;
}

.bold {
  font-weight: bold;
}

.bg-primary {
  background-color: $blue !important;
}

.nav-link {
  display: inline-block !important;
}

.nav-right {
  position: absolute;
  right: 10px;
  font-size: 12px;
}

.navbar {
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  word-break: break-word;
  margin: 0;
  border-radius: 0;
  transition: all 1s;
  -webkit-transition: all 1s;
  @include for-phone-only {
    margin: 15px 50px;
  }
}

.card:hover,
.card:focus,
.card:active {
  transform: scale(1.02);
  box-shadow: 4px 4px 12px 6px rgba(0, 63, 135, 0.5);
  cursor: pointer;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.card-delete {
  color: $white;
  text-align: right;
  width: 1.5em;
  height: 1.5em;
  font-size: 1.25em;
  position: absolute;
  right: 0px;
  border-top: 0px solid transparent;
  border-bottom: 2.5em solid transparent;
  border-right: 2.5em solid $blue;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.card-delete:hover,
.card-delete:focus,
.card-delete:active {
  color: $blue;
  border-top: 0px solid transparent;
  border-bottom: 2.5em solid transparent;
  border-right: 2.5em solid rgb(186, 216, 10);
}

.card-delete .glyphicon-remove {
  right: -1.3em;
  top: 0.1em;
}

// .card-body {
//   padding: 1rem;
// }

.card-image {
  border-radius: 0;
}

.card-svg {
  padding: 1rem;
}

.card-title {
  font-size: 0.8rem;
  text-transform: capitalize;
  font-family: $mont;
  @include for-phone-only {
    font-size: 1.2rem;
  }
}

.card-text {
  font-size: 0.7rem;
  @include for-phone-only {
    font-size: 1rem;
  }
}

.card .Color {
  position: absolute;
  border-radius: 50%;
}

.card .Primary {
  z-index: 10;
  top: 0.625rem;
  left: 0.625rem;
  width: 1.5rem;
  height: 1.5rem;
}

.card .Secondary {
  top: 0.3rem;
  left: 0.3rem;
  width: 2.16rem;
  height: 2.16rem;
}

.when-where {
  font-size: 0.7rem;
  @include for-phone-only {
    font-size: 1rem;
  }
}

.btn-primary {
  background-color: $blue;
  border: none;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: $light-green;
  color: $black;
}

.btn-circle {
  display: flex !important;
  border-radius: 50% !important;
  align-items: center;
  justify-content: center;
}

.btn-submit {
  display: block;
  margin: 0 auto;
  padding: 0.8rem 4rem;
}

.btn-camera {
  padding: 0.65rem !important;
  border-radius: 50% !important;
  width: 150px;
  height: 150px;
  font-size: 1.6rem;
  margin: -80px auto 0px auto;
  display: block;
  z-index: 300000;
  position: relative;
  box-shadow: none;
}

.btn-camera.btn-success {
  width: 150px;
  height: 150px;
  font-size: 3rem;
}

.open-camera {
  margin: 0 auto 34px auto;
  width: 150px;
  height: 150px;
  .glyphicon-camera {
    font-size: 3rem;
  }
}

.barcode-preview {
  position: fixed;
  z-index: 99;
  top: 10vh;
  width: 640px;
  left: 50%;
  margin-left: -320px;
}

.list-message-bottom {
  text-align: center;
  padding: 10px 0;
}

.resolutionAlert {
  position: fixed;
  z-index: 999999;
  top: 30vh;
  left: 10vw;
  width: 80vw !important;
  button {
    margin-right: 1em;
    margin-top: 1em;
  }
}

.when-found-column {
  width: 150px;
  @include for-phone-only {
    width: 78px;
  }
}

.sortable.when-found-column {
  width: 180px;
  @include for-phone-only {
    width: 105px;
  }
}

.where-found-column {
  width: 20%;
}

.ticket-column {
  width: 160px;
  @include for-phone-only {
    width: 84px;
  }
}

.attr-row {
  .attr-field,
  .attr-value {
    display: inline-flex;
  }
  .attr-field {
    padding-right: 8px;
    text-transform: uppercase;
  }
  .attr-value {
    text-transform: lowercase;
  }
}

.scanner {
  .btn {
    position: absolute;
    top: 10px;
    right: 30px;
  }
}

#error-page {
  width: 100%;
  margin: 80px 0;
  text-align: center;
  font-size: 1.2rem;
  h1 {
    font-family: $mont;
    font-size: 5rem;
    margin-bottom: 40px;
  }
  i {
    color: $gray;
    font-weight: lighter;
  }
}

@media (min-width: 576px) {
  .card {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .card {
    width: 16.6%;
  }
}

@media (min-width: 2000px) {
  .card {
    width: 10%;
  }
}
