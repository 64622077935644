@import "variables";
@import "mixins";

.btn-add {
  height: 50px;
  width: 50px;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #003f87;
  font-family: $mont;
  text-transform: uppercase;
}

.section {
  padding: 2vw;
}

tr {
  cursor: pointer;
}

.user-modal {
  .inline-checkbox {
    padding-top: calc(0.375rem + 8px);
    padding-left: 0;
    input {
      margin: 0;
      padding: 0;
      width: 20px;
      height: 20px;
    }
  }

  .modal-footer {
    display: block;
    .btn-delete {
      float: left;
    }
    .btn-modal-cancel,
    .btn-modal-submit {
      float: right;
    }
    .btn-modal-submit {
      margin-right: 10px;
    }
  }
}

.users {
  .user-search-bar {
    .col {
      flex: none;
      width: fit-content;
    }
  }
  table {
    max-height: 600px;
    overflow-y: scroll;
    .short-col {
      width: 5rem;
    }
  }
  .button-text {
    font-family: $fira;
    font-size: 18px;
    margin: 4px 0 -4px 0;
  }
}

.settings {
  table {
    max-height: 300px;
    overflow-y: scroll;
  }
}

#app-version {
  text-align: right;
}
