@import "variables";
@import "mixins";

.notification {
  width: 75vw;
  margin: 5vh auto 0 auto;
  img {
    width: 75vw;
    margin: 0 auto;
  }
  @include for-tablet-portrait-up {
    width: 50vw;
    margin: 8vh auto 0 auto;
    img {
      width: 50vw;
    }
  }
}

.heading {
  text-align: center;
  padding-top: 50px;
}

.top-section {
  width: 100%;
  @include for-tablet-portrait-up {
    padding-top: 10vh;
    display: grid;
    grid-template-columns: repeat(8, [col-start] 1fr);
  }
}

.bottom-section {
  padding: 10vh 20px;
  width: 100%;
  @include for-tablet-portrait-up {
    padding: 10vh 0;
    display: grid;
    grid-template-columns: repeat(8, [col-start] 1fr);
    grid-template-rows: 5vh 5vh auto 5vh auto 5vh;
  }
}

.light-blue-bg {
  background-color: #cce4f4;
  @include for-tablet-portrait-up {
    grid-column: col-start / span 6;
    grid-row: 1 / 7;
  }
}

.desc {
  font-size: 0.8rem;
  @include for-tablet-portrait-up {
    grid-column: col-start 2 / span 3;
    grid-row: 2 / 4;
    font-size: 1.4vw;
  }
}

.grey-bg {
  background-color: #e3e7e9;
  @include for-tablet-portrait-up {
    grid-column: col-start 6 / span 3;
    grid-row: 2 / 5;
  }
}

.login {
  align-items: center;
  @include for-phone-only {
    padding-top: 50px;
  }
  @include for-tablet-portrait-up {
    grid-column: col-start 7;
    grid-row: 3;
  }
  .label {
    font-size: 1.6rem;
  }
}

.hours {
  @include for-phone-only {
    margin-top: 20px;
  }
  @include for-tablet-portrait-up {
    grid-column: col-start 2 / span 2;
    grid-row: 5;
  }
}

.contact {
  @include for-phone-only {
    margin-top: 20px;
  }
  @include for-tablet-portrait-up {
    grid-column: col-start 4 / span 2;
    grid-row: 5;
  }
}

.instructions {
  padding: 10px 20px;
}

.label {
  color: $blue;
  font-size: 1.2rem;
  font-family: $mont;
  text-transform: uppercase;
}

.desc-title {
  padding-bottom: 16px;
  font-size: 1.8rem;
  @include for-tablet-portrait-up {
    font-size: 1.8vw;
  }
}

.desc-info {
  padding: 64px 12.5vw 0 12.5vw;
}

.find .label {
  padding: 16px 0 10px 0;
}

.add-lost {
  @include for-phone-only {
    padding: 50px 20px;
  }
  @include for-tablet-portrait-up {
    grid-column: col-start 2 / span 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 28% 40% 28%;
    grid-row-gap: 2%;
  }

  .label {
    font-size: 2.4vw;
    font-weight: bold;
    @include for-phone-only {
      font-size: 8vw;
    }
    @include for-tablet-portrait-up {
      grid-row: 1;
    }
  }

  .top-desc {
    @include for-phone-only {
      font-size: 1rem;
      margin-top: 40px;
    }
    @include for-tablet-portrait-up {
      grid-row: 2;
      font-size: 1.4vw;
    }
  }

  .add-lost-button-container {
    @include for-phone-only {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
    @include for-tablet-portrait-up {
      width: 100%;
      grid-row: 3;
      position: relative;
    }

    .add-lost-button {
      @include for-tablet-portrait-up {
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        left: 25%;
        width: 50%;
        min-width: 140px;
        font-size: 1.3vw;
        padding: 1.1vw;
      }
    }
  }
}

.infodesk {
  @include for-phone-only {
    width: 100%;
  }
  @include for-tablet-portrait-up {
    grid-column: col-start 5 / span 3;
  }
}

.hours-times,
.hours-addendum,
.contact-info,
.login-info {
  font-size: 0.8rem;
  @include for-tablet-portrait-up {
    font-size: 1.4vw;
  }
}

.hours-addendum {
  font-size: 12px;
  font-style: italic;
  @include for-tablet-portrait-up {
    font-size: 1vw;
  }
}

.wwu-button {
  background-color: $blue;
  color: $white;
  border: none;
  border-radius: 0;
  padding: 10px 40px;
  font-size: 1.2rem;
  font-family: $mont;
  font-weight: bold;
  text-transform: uppercase;
}

.login {
  text-align: center !important;
  @include for-tablet-portrait-up {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 32% 32% 32%;
    grid-row-gap: 2%;
    width: 300px;
  }

  .button-container {
    text-align: center;
    width: 100%;
  }

  .label {
    @include for-tablet-portrait-up {
      grid-row: 1;
    }
  }

  .login-info {
    margin-top: 20px;
    @include for-tablet-portrait-up {
      margin: 0;
      grid-row: 2;
      font-size: 1.4vw;
    }
  }

  .login-button {
    margin-top: 30px;
    width: 100%;
    @include for-phone-only {
      width: 40%;
    }
    @include for-tablet-portrait-up {
      margin: 0;
      grid-row: 3;
      font-size: 1.3vw;
      padding: 1.1vw;
    }
  }
}
