@import "variables";

.wwu-footer {
  color: #fff;
  background: #003f87;
  a:not(.button) {
    color: #41b6ff;
    &:hover,
    &:focus,
    &:active {
      color: #d6e86c;
      background-color: #002f5e;
    }
  }
  ul li a {
    border-bottom-color: transparent;
    &:hover,
    &:focus,
    &:active {
      border-bottom-color: #d6e86c;
    }
  }
  ul.menu li {
    border-left: solid 2px #003f87;
    a {
      color: #fff;
      &:hover,
      &:focus,
      &:active {
        color: #d6e86c;
        background-color: #003f87;
      }
    }
  }
  @media (min-width: 841px) {
    ul.menu li {
      border-left-width: 3px;
    }
  }
  .social-media-links .social-icon {
    color: #003f87;
    outline: none;
    background-color: #fff;
    svg {
      fill: #003f87;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: #bad80a;
      svg {
        fill: #003f87;
      }
    }
    &:focus {
      outline: dotted #41b6ff;
    }
  }
}

.social-icon {
  display: block;
  width: 58px;
  height: 58px;
  transition: ease 400ms;
  background-color: #007ac8;
  color: #fff;
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: #003f87;
  }
  svg {
    width: 100%;
    margin-top: 8px;
    margin-left: 9px;
    transition: fill ease 400ms;
    fill: #fff;
    padding: 0;
  }
}

.social-media-links {
  display: flex;
  flex-flow: row wrap;
  max-width: 400px;
  padding: 0;
  list-style: none;
  @media (min-width: 841px) {
    max-width: none;
  }
  .social-icon {
    margin: 12px 12px 0 0;
  }
}

.logo {
  width: 266px;
  height: 136px;
  padding: 12px;
  background-color: #003f87;
  @media (min-width: 1300px) {
    width: 306px;
    height: 154px;
  }
}

.logo.stacked {
  height: 170px;
  @media (min-width: 1300px) {
    height: 196px;
  }
}

.logo svg {
  width: 100%;
  height: 100%;
}

.logo.full-color,
.logo.grayscale,
.logo.black {
  background-color: #fff;
}

.logo.reversed {
  background-color: #262b2f;
}

.logo .cls-1 {
  fill: #003f87;
}

.logo .cls-2 {
  fill: #007ac8;
}

.logo .cls-3 {
  fill: #262b2f;
}

.logo .cls-4 {
  fill: #808285;
}

.logo .cls-5 {
  fill: #fff;
}

.wwu-footer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 12px 1fr 12px;
  -ms-grid-rows: 12px auto 12px auto 12px auto 12px;
  grid-template-areas: ". ....  ." ". logo  ." ". ....  ." ". info  ." ". ..... ." ". icons .";
  grid-template-columns: 12px 1fr 12px;
  grid-template-rows: repeat(3, 12px auto) 12px;
  justify-items: center;
}

@media (min-width: 581px) {
  .wwu-footer {
    -ms-grid-columns: 12px 1fr 2fr 12px;
    -ms-grid-rows: 36px auto auto 36px;
    grid-template-areas: ". .... ..... ." ". logo info  ." ". logo icons ." ". .... ..... .";
    grid-template-columns: 12px 1fr 2fr 12px;
    grid-template-rows: 36px auto auto 36px;
    justify-items: normal;
  }
}

@media (min-width: 1300px) {
  .wwu-footer {
    -ms-grid-columns: 1fr 433.33333px 866.66667px 1fr;
    grid-template-columns: 1fr 433.33333px 866.66667px 1fr;
  }
}

.wwu-footer a {
  color: #fff;
  border-bottom-color: #bad80a;
}

.wwu-footer a:hover,
.wwu-footer a:focus,
.wwu-footer a:active {
  color: #41b6ff;
  border-bottom-color: transparent;
}

.wwu-footer .logo {
  margin: auto;
  grid-area: logo;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}

@media (min-width: 581px) {
  .wwu-footer .logo {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
  }
}

.wwu-footer .western-info {
  text-align: center;
  grid-area: info;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
}

@media (min-width: 581px) {
  .wwu-footer .western-info {
    text-align: right;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
  }
}

.wwu-footer .social-media-links {
  justify-content: center;
  grid-area: icons;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 6;
  -ms-grid-row-span: 1;
}

@media (min-width: 581px) {
  .wwu-footer .social-media-links {
    justify-content: flex-end;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    justify-self: end;
  }
}

.wwu-footer .social-media-links .social-icon {
  margin: 12px 0 0 12px;
}
