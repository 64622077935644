@media print {
  #root {
    overflow-x: inherit !important;
  }
  .container {    
    max-width: none !important;
  }
  header,
  .beta,
  .form-title,
  form,
  .icon-print,
  .matches-container,
  .toast-container,
  footer {
    display: none !important;
  }
  .barcode {
    width: 310px;
    margin: 0;
    transform: rotate(-90deg) translateX(-78px) translateY(-84px);
  }
  .qrcode {
    display: block;
    width: 310px;
    margin: 0;
    transform: rotate(-90deg) translateX(-88px) translateY(-80px);
  }
}
