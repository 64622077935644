@import "variables";
@import "mixins";


.form {
  padding: 1rem 0;
  width: 95vw;
  max-width: 600px;
  margin: 0 auto;
  .form-header {
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  .form-row {
    row-gap: 0.5rem;
  }
}

.lost-form {
  padding: 2rem 0;
}

.phone-input-container {
  width: fit-content;
  input {
    width: fit-content;
    max-width: 200px;
  }
}

.react-datepicker__input-container {
  max-width: 180px;
}

.alert {
  padding: 5vh;
  width: 95vw;
  max-width: 600px;
  margin: 0 auto 5vh auto;
}

.btn-confirm-image {
  padding: 0.65rem !important;
  border-radius: 50% !important;
  width: 150px;
  height: 150px;
  font-size: 3rem;
  margin: -80px auto 0px auto;
  display: block;
  z-index: 300000;
  position: relative;
  box-shadow: none;
}

.btn-remove-image {
  border-radius: 50%;
  height: 75px;
  width: 75px;
  margin-left: -75px;
  font-size: 1.4rem;
  .glyphicon-remove {
    top: 3px;
  }
}

.btn-cancel-image {
  border-radius: 50%;
  height: 75px;
  width: 75px;
  position: relative;
  left: 712px;
  top: -110px;
  font-size: 1.4rem;
  .glyphicon-remove {
    top: 3px;
  }
}

.image-container {
  display: flex;
}

.with-image {
  margin-top: -80px;
}

.btn-barcode {
  cursor: pointer;
}

.btn-barcode:hover .icon-print,
.btn-barcode:focus .icon-print {
  color: $light-green;
}

.btn-barcode:hover g,
.btn-barcode:focus g {
  fill: $blue !important;
}

.image-column {
  display: flex;
  justify-content: center;
}

.barcode {
  width: 310px;
  margin: 10px auto 40px auto;
}

.qrcode {
  display: none;
}

.icon-print {
  width: 22px;
  margin: -27px auto 0 auto;
  display: block;
  padding-left: 246px;
}

.invalid-feedback {
  margin-top: -0.5rem;
  margin-bottom: 0.75rem;
}

.circle-picker {
  flex-wrap: wrap !important;
  padding: 0.5rem 0;
}

#primary-color-indicator,
#secondary-color-indicator {
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.5rem;
  display: inline-block;
}

div[title="copper"] {
  box-shadow: #b87333 0px 0px 0px 14px inset;
}

div[title="clear"] {
  box-shadow: #ffffff 0px 0px 0px 14px inset;
  opacity: 10%;
  border: solid 1px $black;
}

.resolution-group label {
  @include for-phone-only {
    font-size: 12px;
  }
}

.matches-container {
  padding-bottom: 30px;
}

.potential-matches {
  display: flex;
  @include for-phone-only {
    display: block;
  }
}

.potential-matches-title {
  padding-bottom: 10px;
  display: flex;
  @include for-phone-only {
    display: block;
  }
}

@media (min-width: 1200px) {
  .form,
  .alert {
    max-width: 900px;
  }
  .circle-picker {
    flex-wrap: nowrap !important;
  }
  .circle-picker span div {
    margin-right: 9.5px !important;
    margin-bottom: 0 !important;
  }
}

.lost-form .form-control {
  border-color: $blue;
}