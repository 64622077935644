@import "variables";
@import "mixins";

.site-header:has(+ .sale-report) {
    display: none;
}

.title {
    font-family: $mont;
    font-size: 54px;
    font-weight: bolder;
    text-transform: uppercase;
    color: $blue;
}
.set-discount {
    .form-label {
        margin-bottom: 0;
        margin-right: 10px;
    }
    .form-control {
        width: 58px;
        margin-bottom: 0;
        text-align: center;
    }
}
.transactions {
    max-height: 466px;
    overflow-y: auto;
    overflow-x: hidden;
}
.transactions::-webkit-scrollbar {
    width: 1em;
}
.transactions::-webkit-scrollbar-track {
    background-color: $white;
    border-radius: 8px;
    margin: 20px 0;
}
.transactions::-webkit-scrollbar-thumb {
    background-color: $blue;
    border-radius: 8px;
}
.total {
    font-size: 52px;
    font-weight: bolder;
    text-align: right;
    background-color: $blue;
    color: $light-green;
    border-radius: 20px;
    font-family: $mont;
    text-transform: uppercase;
}
.line-chart {
    background-color: white;
    border-radius: 20px;
}
.sale-item-image {
    width: 40px;
}

.input-group-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-middle {
    border-radius: 0;
    border-left: 0;
}
