@import "variables";
@import "mixins";

hr {
    width: 90%;
    margin: 1rem auto;
}

.large-text {
    font-size: 3rem;
    text-align: center;
}

.delete-icon-button {
    padding-top: 10px;
}

.sale-toast {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
}

.ticket-input {
    text-align: center;
}

.qr-container {
    section {
        border-radius: 20px;
    }
}

.item-image {
    border-radius: 20px;
}

.dollar-sign {
    font-size: 40px;
    margin-right: 20px;
}
.price-field {
    margin-top: 6px;
    padding-left: 10px;
    width: 135px;
}
.discount {
    font-size: 40px;
    font-weight: bold;
    color: $red;
    text-transform: uppercase;
}