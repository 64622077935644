@import "mixins";

.search-bar {

  row-gap: 1.5rem;

  .form-inline .form-group {
    line-height: 70px;
    display: inline-flex;
    @include for-phone-only {
      line-height: 46px;
      display: flex;
    }
    .react-datepicker,
    .react-datepicker__week {
      line-height: normal;
    }
  }

  .date-row {
    flex-wrap: nowrap;
    .col-md-auto {
      width: auto;
    }
  }

  .btn-row {    
    flex-wrap: nowrap;
    width: fit-content;
    .col-md-auto {
      width: auto;
    }
  }

  .form-label {
    margin-bottom: 0;
  }

  .btn {
    height: 50px;
  }

  .btn-add {
    width: 50px;
  }

  .date-control {
    width: 148px !important;
    margin-left: 0;
  }

  .date-search-dash {
    align-self: center;
    padding: 0;
  }

  .react-datepicker-wrapper {
    width: auto;
  }

  .react-datepicker-popper {
    z-index: 20 !important;
  }
}
